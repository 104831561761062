<template>
	<b-row>
		<b-col
		lg="8">
			<view-component
			model_name="salon">
				<template v-slot:mapa="data">
					<salon
					modo="editar"
					:salon="salon"></salon>
				</template>
			</view-component>
		</b-col>
	</b-row>
</template>
<script>
export default {
	components: {
		ViewComponent: () => import('@/common-vue/components/view/Index'),
		Salon: () => import('@/components/salon/Salon'),
	},
	computed: {
		salon() {
			return this.$store.state.salon.model 
		},
	},
}
</script>